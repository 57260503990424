import { useState, useEffect } from 'react';
import FoodVendor from '../FoodVendor';
import DrinkVendor from '../DrinkVendor';
import './VendorContainer.css';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export default function VendorContainer() {
  const vendors = [
    () => {
      return <FoodVendor />;
    },
    () => {
      return <DrinkVendor />;
    },
  ];

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  const [vendorIdx, setVendorIdx] = useState(vendors.length - 1);

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [windowDimensions]);

  const getVendors = () => {
    //* mobile
    if (windowDimensions.width / windowDimensions.height < 1.135416) {
      return (
        <div className='vendor-container'>
          {vendors[vendorIdx]()}
          <img
            className='vendor-switch-left'
            src={process.env.PUBLIC_URL + '/img/arrow.png'}
            alt='vendor'
            onClick={() => setVendorIdx((vendorIdx) => Math.max(0, vendorIdx - 1))}
            style={{ display: vendorIdx > 0 ? 'block' : 'none' }}
          />
          <img
            className='vendor-switch-right'
            src={process.env.PUBLIC_URL + '/img/arrow.png'}
            alt='vendor'
            onClick={() => setVendorIdx((vendorIdx) => Math.min(vendors.length - 1, vendorIdx + 1))}
            style={{ display: vendorIdx < vendors.length - 1 ? 'block' : 'none' }}
          />
        </div>
      );
    }
    //* PC
    return (
      <div className='vendor-container'>
        <FoodVendor />
        <DrinkVendor />
      </div>
    );
  };

  return getVendors();
}
