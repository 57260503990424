import { useEffect, useState, useContext } from 'react';
import { ethers } from 'ethers';
import contract from '../../contracts/FoodNFTCollectible.json';
import { StatusContext } from '../../App';
import './DrinkVendor.css';

const contractAddress = null;
const abi = contract.abi;
const isRinkeby = true;
const isSoldOut = false;

export default function DrinkVendor() {
  //! quantity
  const MIN_QUALITY = 1;
  const MAX_QUALITY_FREE = 2;
  const MAX_QUALITY_PAID = 5;
  const [maxQuality, setMaxQuality] = useState(MAX_QUALITY_FREE);
  const [quantity, setQuantity] = useState(MIN_QUALITY);

  //! mint count
  const TOTAL_MINT = 6000;
  const FREE_MINT = 1000;
  const [mintCount, setMintCount] = useState(isSoldOut ? TOTAL_MINT : 0);

  //! price
  const PRICE_FREE = 'FREE';
  const PRICE_PAID = '0.0078 eth';
  const [price, setPrice] = useState('?????');

  //! light
  const [lightIdx, setLightIdx] = useState(0);
  const [lightImgs] = useState(['/img/light_04.png', '/img/light_05.png', '/img/light_06.png']);

  //! mint light
  const [mintLightIdx, setMintLightIdx] = useState(0);
  const [mintLightImgs] = useState([
    '/img/mint_light_04.png',
    '/img/mint_light_05.png',
    '/img/mint_light_06.png',
    '/img/empty.png',
    '/img/empty.png',
    '/img/empty.png',
    '/img/empty.png',
    '/img/empty.png',
    '/img/empty.png',
    '/img/empty.png',
    '/img/empty.png',
    '/img/empty.png',
    '/img/empty.png',
    '/img/empty.png',
  ]);

  //! light
  useEffect(() => {
    const updateLight = () => {
      setLightIdx((lightIdx) => (lightIdx + 1) % lightImgs.length);
    };

    const timerId = setInterval(() => {
      updateLight();
    }, 100);

    return () => clearInterval(timerId);
  }, [lightImgs.length]);

  //! mint light
  useEffect(() => {
    const updateMintLight = () => {
      setMintLightIdx((mintLightIdx) => (mintLightIdx + 1) % mintLightImgs.length);
    };

    const timerId = setInterval(() => {
      updateMintLight();
    }, 150);

    return () => clearInterval(timerId);
  }, [mintLightImgs.length]);

  //! quantity
  const add = () => {
    updateQuantity(quantity + 1);
  };

  const sub = () => {
    updateQuantity(quantity - 1);
  };

  const updateQuantity = (quan) => {
    if (quan) {
      quan = Math.min(maxQuality, Math.max(MIN_QUALITY, quan));
      setQuantity(quan);
    }
  };

  //! web3 APIs
  const { currentAccount, updateStatus } = useContext(StatusContext);
  const [isBusy, setIsBusy] = useState(false);

  const mintNftHandler = async () => {
    if (isSoldOut) {
      alert(
        `Thank you for your interest. \nGohan-kun is sold out. \nPlease check https://opensea.io/collection/gohan-kun.`
      );
      window.open('https://opensea.io/collection/gohan-kun');
      return;
    }

    try {
      const { ethereum } = window;

      if (ethereum) {
        if (currentAccount === null) {
          updateStatus('Please connect wallet first');
          return;
        }
        if (isBusy) {
          updateStatus('Busy... please wait');
          return;
        }
        if (!contractAddress || contractAddress === '') {
          updateStatus('Contract is not available');
          return;
        }
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        const nftContract = new ethers.Contract(contractAddress, abi, signer);

        updateStatus('Initialize mint');
        setIsBusy(true);

        let nftTxn;
        if (mintCount < FREE_MINT) {
          nftTxn = await nftContract.freeMint(quantity);
        } else {
          nftTxn = await nftContract.mint(quantity, {
            value: ethers.utils.parseEther((0.0078 * quantity).toString()),
          });
        }

        updateStatus(`Mint (price: ${price}, quantity: ${quantity})... please wait`);

        await nftTxn.wait();

        updateStatus(`Mined, see transction: https://${isRinkeby ? 'rinkeby.' : ''}etherscan.io/tx/${nftTxn.hash}`);
        setIsBusy(false);
      } else {
        updateStatus('Ethereum object does not exist.');
        setIsBusy(false);
      }
    } catch (err) {
      updateStatus(err.message || 'error');
      setIsBusy(false);
    }
  };

  useEffect(() => {
    const updateMintCount = async () => {
      try {
        const { ethereum } = window;
        if (ethereum && currentAccount && contractAddress && contractAddress !== '') {
          const provider = new ethers.providers.Web3Provider(ethereum);
          const signer = provider.getSigner();
          const nftContract = new ethers.Contract(contractAddress, abi, signer);
          let countTxn = await nftContract.totalSupply();
          const count = Number(countTxn._hex);
          if (count) {
            setMintCount(count);
            if (count < FREE_MINT) {
              setMaxQuality(MAX_QUALITY_FREE);
              setPrice(PRICE_FREE);
            } else {
              setMaxQuality(MAX_QUALITY_PAID);
              setPrice(PRICE_PAID);
            }
          }
        }
      } catch (err) {
        updateStatus(err);
      }
    };

    const timerId = setInterval(() => {
      updateMintCount();
    }, 500);

    return () => clearInterval(timerId);
  }, [currentAccount, mintCount, updateStatus]);

  return (
    <div className='drink-vendor-container'>
      <img className='drink-vendor' src={process.env.PUBLIC_URL + '/img/vendor_drink.png'} alt='vendor' />
      <img className='drink-vendor' src={process.env.PUBLIC_URL + lightImgs[lightIdx]} alt='light' />
      <img className='drink-vendor' src={process.env.PUBLIC_URL + '/img/vendor_drink_cover.png'} alt='cover' />
      <div className='drink-vendor-mint-count'>
        <span>{mintCount}</span>
        {` / ${TOTAL_MINT} MINT`}
      </div>
      <img
        className='drink-vendor-mint'
        src={process.env.PUBLIC_URL + (isSoldOut ? '/img/btn_sold_out_drink.png' : '/img/btn_mint_drink.png')}
        alt='mint_button'
        onClick={mintNftHandler}
      />
      <img className='drink-vendor-quantity' src={process.env.PUBLIC_URL + '/img/quantity_drink.png'} alt='quantity' />
      <img
        className='drink-vendor-add'
        src={process.env.PUBLIC_URL + '/img/quantity_add_drink.png'}
        onClick={add}
        alt='add'
      />
      <img
        className='drink-vendor-sub'
        src={process.env.PUBLIC_URL + '/img/quantity_sub_drink.png'}
        onClick={sub}
        alt='sub'
      />
      <div className='drink-vendor-quantity-text'>{quantity}</div>
      <img className='drink-vendor' src={process.env.PUBLIC_URL + mintLightImgs[mintLightIdx]} alt='mint_light' />
      <div className='drink-vendor-price'>Price: {price}</div>
      <div className='drink-vendor-contract'>
        <a
          href={`https://${isRinkeby ? 'rinkeby.' : ''}etherscan.io/address/${contractAddress}`}
          target='_blank'
          rel='noreferrer'>
          Contract
        </a>
      </div>
    </div>
  );
}
